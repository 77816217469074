<template>
    <div>
        <b-row>
            <b-col cols="12">
                <zw-table ref="table"
                          tableName="channelArticles"
                          columnsPrefix="multichannel.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template #cell(synced)="row">
                        <CBadge :color="row.item.synced ? 'success' : 'danger' ">
                            {{ row.item.synced ? $t('common.button.yes') : $t('common.button.no') }}
                        </CBadge>
                    </template>

                    <template #cell(purchase_price)="row">
                        {{ row.item.purchase_price | priceFormat }} &euro;
                    </template>

                    <template #cell(sales_price)="row">
                        {{ row.item.sales_price | priceFormat }} &euro;
                    </template>

                    <template #cell(article_id)="row">
                        <a v-if="row.item.article_id"
                           href="#"
                           @click.prevent="$root.$children[0].openModal('article-modal', {id:row.item.article_id}, refreshTable)"
                        >{{ row.item.article_id }}</a>
                        <p v-else>
                            0
                        </p>
                    </template>

                    <template #cell(actions)="row">
                        <b-button
                            @click="sync(row.item.id)"
                            size="sm"
                            variant="info"
                            class="mr-2"
                        >
                            <font-awesome-icon icon="cloud-upload-alt"/>
                        </b-button>
                        <b-button
                            @click="openEditModal(row.item.id)"
                            size="sm"
                            variant="info"
                            class="mr-2"
                            :title="$t('common.title.edit')"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>
                        <b-button @click="deleteSelected(row.item.id)"
                                  size="sm"
                                  variant="danger"
                                  :title="$t('common.title.delete')"
                        >
                            <font-awesome-icon icon="trash"/>
                        </b-button>

                        <b-row v-if="!row.item.article_id">
                            <b-col cols="7">
                                <search-field v-model="search"
                                              name="search"
                                              :key="'key_'+row.item.id"
                                              :url="articleSearchUrl"
                                              disable-label
                                              :placeholder="$t('common.button.create_article')"
                                              @input-object="articleSelected"
                                ></search-field>
                            </b-col>
                            <b-col cols="3">
                                <b-button @click="assignItem(row.item.id)"
                                          variant="success"
                                          size="sm"
                                          :title="$t('common.title.add')"
                                >
                                    <font-awesome-icon icon="save"></font-awesome-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>

                </zw-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'


export default {
    name: 'MultistoreArticles',
    mixins: [commonTable],
    props: [
        'sourceId',
        'storeId',
        'apiType',
    ],
    data() {
        return {
            loading: true,
            search: null,
            searchFind: null,
            articleSearchUrl: window.apiUrl + '/articles/search',
            saveFilters: true,
            tableUniqKey: this.storeId + '_' + this.sourceId
        }
    },
    methods: {
        ...mapGetters('Multichannel', ['getArticlesTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Multichannel/fetchArticles', 'getArticlesTable');
        },
        ctxAdditionalParams() {
            return {
                'api_source_id':  this.sourceId,
                'api_store_id':  this.storeId,
            }
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('multichannel.button.append'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('append-article-modal', {
                            store_id: this.storeId,
                            source_id: this.sourceId
                        }, this.refreshTable)
                    }
                },
                {
                    title: this.$t('multichannel.button.change_attribute'),
                    icon: 'list',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.openChangeAttribute()
                    }
                },
                {
                    title: this.$t('multichannel.button.update_price'),
                    icon: 'euro-sign',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.openChangePrice()
                    }
                },
                {
                    title: this.$t('multichannel.button.sync'),
                    icon: 'cloud-upload-alt',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.sync()
                    }
                },
                {
                    title: this.$t('multichannel.button.delete_selected'),
                    icon: 'trash',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.deleteSelected()
                    }
                },
                {
                    title: this.$t('article.button.map_article'),
                    icon: 'list',
                    click: () => {
                        this.$root.$children[0].openModal('map-article-modal', '', this.refreshTable, {width: '800px'})
                    }
                },
            ]

            if (['otto-market', 'shopify', 'woocommerce', 'ebay'].includes(this.apiType)) {
                actions.push({
                    title: this.$t('multichannel.button.get_products'),
                    icon: 'cloud-download-alt',
                    click: () => {
                        this.getProducts()
                    }
                });
            }
            if (this.apiType == 'amazon') {
                actions.push({
                    title: this.$t('multichannel.button.get_products'),
                    icon: 'cloud-download-alt',
                    click: () => {
                        this.getAmazonProducts()
                    }
                });
            }
            return actions;
        },
        sync(id = null) {
            this.loading = true;
            let list = []
            if (id) {
                list.push(id)
            } else {
                list = this.selected.map(row => row.id)
            }

            let sendData = {
                ...this.getSelectedData('channelArticles'),
                ids: list,
            }

            return this.$store.dispatch('Multichannel/sync', sendData)
                .then((response) => {
                    if (response.data.batch_group) {
                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                    }
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('multichannel.text.queued_count', {count: response.data.count}),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        articleSelected(article) {
            this.searchFind = article.id;
            this.search = null;
        },
        assignItem(id) {
            if (this.searchFind != null) {
                let data = {
                    article_id: this.searchFind,
                    prev_id: 0,
                    article_network_id: id
                }

                this.$store.dispatch('ChannelArticle/assign', data)
                    .then(() => {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_updated'),
                            'success'
                        )
                    })
                    .finally(() => {
                        this.searchFind = null;
                        this.refreshTable();
                    })
            }
        },
        deleteSelected(id = null) {
            this.loading = true;
            let list = []
            if (id) {
                list.push(id)
            } else {
                list = this.selected.map(row => row.id)
            }

            let sendData = {
                ...this.getSelectedData('channelArticles'),
                ids: list,
            }
            this.$removeConfirm('ChannelArticle/deleteChannelArticles', sendData, this.refreshTable)
        },
        openChangeAttribute() {
            let sendData = this.getSelectedData('channelArticles')

            this.$root.$children[0].openModal('change-store-attribute-modal', {
                sourceId: this.sourceId,
                storeId: this.storeId,
                selectedData: sendData,
            }, this.refreshTable, {width: '800px'})
        },
        openChangePrice() {
            let sendData = this.getSelectedData('channelArticles')

            this.$root.$children[0].openModal('update-store-article-price', {
                sourceId: this.sourceId,
                storeId: this.storeId,
                selectedData: sendData,
            }, this.refreshTable, {width: '800px'})
        },
        openEditModal(id) {
            this.$root.$children[0].openModal('channel-article-modal', {
                id: id
            }, this.refreshTable)
        },
        getAmazonProducts() {
            this.$root.$children[0].openModal('get-amazon-products-modal', {
                storeId: this.storeId,
                sourceId: this.sourceId,
            }, this.refreshTable, {width: '800px'})
        },
        getProducts() {
            return this.$store.dispatch('Multichannel/getProducts', this.sourceId)
                .then((response) => {
                    if (response.data.batch_group) {
                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                    }
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('multichannel.text.queued_count', {count: response.data.count}),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
}
</script>