<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <CCard v-if="!loading">
            <CCardBody>
                <b-tabs content-class="mt-3">
                    <b-tab v-for="store in getStores()"
                           :key="store.id"
                    >
                        <template slot="title">
                            <font-awesome-icon icon="box-open"></font-awesome-icon>
                            {{ store.name }}
                        </template>
                        <multistore-articles :source-id="sourceId"
                                             :store-id="store.id"
                                             :api-type="source.api_type"
                        ></multistore-articles>
                    </b-tab>

                </b-tabs>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import MultistoreArticles from "../../../views/modules/multichannel/articles"
import ChannelArticleModal from "../../../modals/article/ChannelArticleModal";

export default {
    name: 'MultiChannel',
    components: {ChannelArticleModal, MultistoreArticles},
    mixins: [commonTable],
    data() {
        return {
            loading: true,
            sourceId: null,
            source: {},
        }
    },
    mounted() {
        this.sourceId = this.$route.params.id
        const stores = this.$store.dispatch('Multichannel/fetchStores', this.sourceId)

        Promise.all([stores])
            .then(() => {
                this.source = this.getSources().find(source => source.id == this.sourceId)
            })
            .finally(() => {
                this.loading = false
            })
    },
    methods: {
        ...mapGetters('CommonData', ['getSources']),
        ...mapGetters('Multichannel', ['getStores']),
    }
}
</script>